<template>
<div class="login_container">
   <div class="banner">
     <div class="tab_">
     <van-row class="font_w_2 font_1">
       门店:{{reData.storeName}}
     </van-row>
     <van-row class="goods">
       <van-col class="font_2">商品:{{reData.goodsName}}</van-col>
       <van-col class="font_2 num">数量:{{reData.goodsNum}}</van-col>
     </van-row>
      </div>
      <div class="tab_ info">
        <van-row class="font_1 font_w_2 header">订单详情</van-row>
        <van-row class="font_2 title">实付金额:{{(reData.orderAmount*0.01).toFixed(2)}}</van-row>
        <van-row class="font_2 title">订单号码:{{reData.orderNo}}</van-row>
        <van-row class="font_2 title">下单时间:{{reData.serverCreateTime}}</van-row>
         <van-row class="font_2 title">订单状态:{{reData.orderStatus=='PATING'?'待支付':(reData.orderStatus=='WAIT_DELIVERY'?'待核销':(reData.orderStatus=='SUCCESS'?'订单完成':''))}}</van-row>
        <van-row class="font_2 title">核销时间:{{reData.deliveryTime}}</van-row>
      </div>
      </div>
      <div v-if="reData.orderStatus=='WAIT_DELIVERY'" class="footer">
      <van-row v-if="!reData.buying" class="col_r" @click="payOrder">
        核销
      </van-row>
 </div>
   
   </div>
</template>

<script>
export default {
    data() {
    return {
      logoSrc:"",
      appSrc:"http://www.baidu.com",
      reData:{},
    }
  },
  created(){
    this.getInfo();
  },
  methods: {
    async getInfo(){
        var pram={
         "orderNo": window.sessionStorage.getItem('orderNo'),
          "verificationTicket": window.sessionStorage.getItem('verificationTicket')
        }
        const {data:res}=await this.$http.get("/applet/verification/order/detail",pram,{
      });
      if(res.success){
        this.reData=res.data
        this.appSrc=res.data.orderNo
      }else{
         return this.$message.error(res.msg)
      }
       
    },
   async payOrder(){
         var pram={
         "orderNo": window.sessionStorage.getItem('orderNo'),
          "verificationTicket": window.sessionStorage.getItem('verificationTicket')
        }
      const {data:res}=await this.$http.post("/applet/verification/order/verify",pram,{
        Headers:{'Content-type': 'application/x-www-form-urlencoded'}
      });
      if(res.success){
        alert("核销成功")
        window.sessionStorage.removeItem("orderNo")
         this.$router.push('/applet/verify');
      }else{
         return this.$message.error(res.msg)
      }
    },
 
  }
    
}
</script>
<style lang="less" scoped>
.login_container{
 display: flex;
  flex-direction: column;
  min-height: 100%;
}
.banner{
    flex: 1;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    bottom: 1.73333rem;
    padding: 50px;
  .tab_{
    border: 1px solid rgb(187, 185, 185);
    padding: 50px 30px;
    border-radius: 20px;
  }
}
.banner_center{
  text-align: center;
  padding-top: 30px;
}
.num{
  margin-left: 40px;
}
.goods{
  margin-top: 20px;
}
.fx{
  border: dashed 2px rgb(202, 199, 199);
  margin: 30px 0;
}
.info{
  margin-top: 30px;
  .header{
    border-bottom: 2px solid rgb(202, 199, 199);
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.title{
  height: 50px;
  line-height: 50px;
}
.footer{
  flex: 0;
		height: 130px;
			position: absolute;
z-index: 10;
bottom: 0;
left: 0;
right: 0;
text-align: center;
background-color: hsl(0, 11%, 95%);
border: solid 1px rgb(189, 186, 186);

    .col_r{
      width: 100%;
      background-color: rgb(202, 19, 19);
      font-size: 30px;
      line-height: 130px;
    }
		}
</style>
