<template>
    <div class="login_container font_1">
      <div :class="misicState?'misic misicOpen':'misic'">
        <div v-if="!misicState" class="misic_line"></div>
        <audio id="SGsound" :src="reData.activityBgMusicUrl" webkit-playsinline="true" playsinline="true" preload @click="checkAuto"></audio>
      </div>
      <div class="wrapper">
      <van-row class="top_2">
        <img :src="reData.activityPicUrl" alt="">
      </van-row>
  <van-row class="center_1">
    <van-row class="row">[{{reData.activityName}}]{{reData.activityDesc}}</van-row>
    <van-row class="row colorRed ">
      <span class="font_w_2 price_t">¥{{(reData.stock*0.01).toFixed(2)}}</span>起<span class="colorGray price_r">|原价¥{{(reData.outPrice*0.01).toFixed(2)}}</span>
      </van-row>
    <van-row class="colorGray font_3 row"><span class="xq_btn">超值</span>{{reData.follows}}人感兴趣</van-row>
    <van-row class="colorGray colorRed font_2 row">活动购买还剩：{{reData.status==3?'已结束':'1天'}}</van-row>
    <van-row class="colorGray font_2 row">活动有效时间：{{reData.activityStartTime.substring(0,10)}}至{{reData.activityEndTime.substring(0,10)}}</van-row>
  </van-row>
  <van-row class="center_2">
    <van-row class="center_2_c">
    <van-row class="center_2_c_t">已有{{buyers.length}}人拼团成功</van-row>
    <van-row class="row_1">
      <van-col v-for="item in buyers" :key="item"  class="img_attr">
        <img :src="item.headImgUrl" alt=""/>
        <p class="font_3">{{item.nickname}}</p>
        </van-col>
    </van-row>
    </van-row>
  </van-row>
  <van-row class="center_3">
    <van-row class="center_3_t">红包排行榜</van-row>
    <van-row class="center_3_row" v-for="(item,index) in buyers" :key="item" >
      <van-col class="col_1">{{index+1}}</van-col>
      <van-col class="col_2"><img :src="item.headImgUrl" alt=""></van-col>
      <van-col class="col_3">{{item.nickname}}</van-col>
      <van-col class="col_4">{{item.shareCount}}人</van-col>
      <van-col class="col_5">{{item.packetAmount?item.packetAmount:0}}元</van-col>
    </van-row>
  </van-row>
  
  <van-row class="center_4">
    <van-row class="center_4_t"><span class="text_"></span><span class="text_c">活动详情</span><span  class="text_"></span></van-row>
    <!-- <van-row class="colorGray font_2">特价礼包需本人凭手机订单到门店领取</van-row>
    <van-row class="colorRed font_2">超值活动，不予退换</van-row>
    <van-row class="colorGray font_2">领取时间：仅限11月20日进店领取（过期失效）</van-row> -->
  <van-row>
     <img :src="reData.activityRuleUrl" alt="">
  </van-row>
  </van-row>
  
  <!-- <van-row class="center_5">
  <van-row class="center_5_t"><span class="text_"></span><span class="text_c">注意事项</span><span  class="text_"></span></van-row>
  <van-row>
     <img :src="reData.activityRuleUrl" alt="">
  </van-row>
  <van-row class="row font_3">营创提供营销策划支持（营创商家联盟认证）</van-row>
  <van-row class="row font_3 colorRed">做同款活动，立即点击</van-row>
  </van-row> -->
      </div>
  <div class="footer">
    <van-row>
      <van-col class="col_l" @click="openModel">分享领红包</van-col>
      <van-col v-if="!reData.buying" class="col_r" @click="payOrderl">
        支付
      </van-col>
      <van-col v-if="reData.buying" class="col_r" @click="toInfo">
        查看订单
      </van-col>
    </van-row>
 </div>
 <div v-if="showLine" class="zz"></div>
 <div v-if="showModel" class="model">
   <div class="m_top">
     <div ><span @click="colseModel">X</span></div>
   </div>
   <div class="m_center">
     <img src="reData.activityShareBgUrl" alt="">
   </div>
   <div class="m_qr">
     <vue-qr :logo-src="logoSrc"
            :size="50"
            :colorDark="red"
            :margin="0"
            :dot-scale="1"
            :text="appSrc" />
   </div>
   <div class="m_bottom">长按图片发送给好友或保存</div>
 </div>
 <div v-if="showAddressModel"  class="model">
   <div class="m_top">
     <div><span @click="colseModel">X</span></div>
   </div>
   <div class="m_center m_ac">
     <van-row :class="(sid==item.id)?'m_c_row m_c_row_active':'m_c_row m_c_row_noactive'" v-for="item in reData.stories" :key="item" @click.stop="getAddressValue(item.id,item.storeCode)">
       {{item.storeName}}
     </van-row>
   </div>
  
   <div class="m_bottom">
     <button @click="getAddress">确定选择</button>
   </div>
 </div>
    </div>
</template>


<script>
  let Base64 = require('js-base64').Base64
export default {
    data() {
    return {
      showLine:false,
      showModel:false,
      showAddressModel:false,
      addressValue:'',
      addressBtn:false,
      sid:null,
      appSrc:"http://www.baidu.com",
      logoSrc:"",
      misicState:true,
      reData:{},
      packets:[
	],
      buyers:[
	],
    }
   
  },
  created(){
      //document.querySelector('#SGsound').play();
       this.getTicket()
       
  },
  methods: {
      async getinfo(){
           const {data:res}=await this.$http.get("/applet/goods/detail?id=1",{});
                if(res.success){
                    this.reData=res.data
                }
           const {data:respackets}=await this.$http.get("/applet/goods/packets?id=1",{});
                if(respackets.success){
                    this.packets=respackets.data
                }
           const {data:resbuyers}=await this.$http.get("/applet/goods/buyers?id=1",{});
                if(resbuyers.success){
                    this.buyers=resbuyers.data
                }
      },
      toInfo(){
           this.$router.push('/applet/info');
      },
      getAddressValue(id,storeCode){
        this.sid=id;
        this.addressValue=storeCode;
        this.addressBtn=false;
      },
      getAddress(){
        this.addressBtn=true;
        this.showAddressModel=false;
        this.showLine=false;
        this.payOrder()

      },
      payOrderl(){
        this.showAddressModel=true;
        this.showLine=true;
      },
    onSubmit() {
        
    },
     async getTicket(){
             let tk=window.sessionStorage.getItem('ticket');
             if(!tk){
               let wxcode= window.sessionStorage.getItem('wxcode')
            if(wxcode){
            //  获取授权
            const {data:res}=await this.$http.post("/applet/auth/official",{ 'code': wxcode });
            if(res.success){
             window.sessionStorage.setItem('ticket',res.data)
             tk=res.data;
             }else{
             return this.$message.error(res.msg)
            }
            }
             }
            
            //解析
            var c = Base64.decode(tk);
             //判断有没有红包授权
            if(!JSON.parse(c).platformOpenid){
               let uOPenId=window.sessionStorage.getItem('uOPenId')
                if(uOPenId){
                    const {data:res}=await this.$http.post("/applet/auth/bind/openid",{ 'openid': uOPenId});
                    if(res.success){
                        window.sessionStorage.setItem('ticket',res.data)
                        window.sessionStorage.setItem("uOPenId","")
                    }
                }else{
                window.location.href = "https://www.yaoyaola.cn/exapi2/check_user/51882?url=http://acrm.blu-rayvision.com&flag=0"
                 return;
                }
            }
         this.appSrc="http://acrm.blu-rayvision.com?super="+JSON.parse(c).id;
        this.getinfo()


    },
    async payOrder() {
      var param={
        activityId: 1,
        storeCode:this.addressValue,
        superMemberId:window.sessionStorage.getItem("superMemberId")?window.sessionStorage.getItem("superMemberId"):'1'
      }
      var tath=this;
      const {data:res}=await this.$http.post("/applet/order/group-by",param);
         if(res.success){
            const pay_params = res.data
            if (typeof WeixinJSBridge === "undefined"){
            if(document.addEventListener){
              document.addEventListener('WeixinJSBridgeReady', tath.onBridgeReady, false);
            }else if (document.attachEvent){
              document.attachEvent('WeixinJSBridgeReady', tath.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', tath.onBridgeReady);
            }
          }else{
            tath.onBridgeReady(pay_params);
          }
        }else{
          alert('微信支付调起失败！');
          tath.$router.push('/applet/loading')


        }
      },
     onBridgeReady(params) {
        var that = this
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": params.appId,  //公众号名称，由商户传入
            "timeStamp": params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            "nonceStr": params.nonceStr,  //支付签名随机串，不长于 32 位
            "package": params.package,//统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            "signType": params.signType,  //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            "paySign": params.paySign, //支付签名
          },
          function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              alert('支付成功！');
              that.$router.push('/applet/info')
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
              alert('支付失败！');
              that.$router.push('/applet/loading')
            }else{
              that.$router.push('/applet/loading')
            }
          });
      },
      checkAuto(){
        var audio = document.querySelector('#SGsound');
        if(audio.paused){
        audio.pause()//暂停
        this.misicState=false
        }else{
        audio.play()//播放
        this.misicState=false
        }
      },
      colseModel(){
        this.showModel=false
        this.showLine=false
        this.showAddressModel=false
      },
      openModel(){
        this.showModel=true
        this.showLine=true
      }


  }
    
}
</script>


<style lang="less" scoped>
.xq_btn{
  width: 40px;
  background-color: red;
  border: red;
  border-radius: 10px;
  color: #ededed;
  padding: 8px;
  margin-right: 10px;
}
.price_t{
  margin-right: 10px;
}
.price_r{
  margin-left: 10px;
  text-decoration:line-through
}
.wrapper{
  flex: 1;
  position: absolute;
	    z-index: 10;
	    top: 0;
	    left: 0;
	    right: 0;
	    overflow-y: auto;
	    bottom: 130px;
  img{
    object-fit: contain;
    border: none !important;
  }

}
.m_ac{
  overflow: scroll;
}
.m_c_row{
    height: 1rem;
    /* padding: 0; */
    margin: 5px 10px;
    line-height: 1rem;
    padding: 0 20px;
}
.m_c_row_noactive{
  border: 1px solid rgb(182, 180, 180);
  color: rgb(126, 125, 125);
}
.m_c_row_active{
  border: 1px solid rgb(118, 236, 63);
  color: rgb(118, 236, 63);;
}
.footer{
  flex: 0;
		height: 130px;
			position: absolute;
z-index: 10;
bottom: 0;
left: 0;
right: 0;
text-align: center;
background-color: hsl(0, 11%, 95%);
border: solid 1px rgb(189, 186, 186);

    .col_l{
      width: 30%;
      color: rgb(202, 19, 19);
      line-height: 130px;
      
    }
    .col_r{
      width: 70%;
      background-color: rgb(202, 19, 19);
      font-size: 30px;
      line-height: 130px;
    }
		}
.center_4{
  padding: 20px;
  text-align: center;
}
.text_{
 display:inline-block;
    width:120px;
    border-top:1px solid #cccccc;
    vertical-align:5px; 
}
.text_c{
  margin: 0 80px;
  
}
.center_5{
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  border-top: solid 20px gainsboro;
  border-bottom: solid 20px gainsboro;
  margin-bottom: 20px;
}
.center_5_t{
  margin-top: 20px;
  margin-bottom: 40px;
}
.center_4_t{
padding-bottom: 40px;

}
.center_3{
  height: auto;
  background-color: #f0f357;
  margin-top: 20px;
  padding: 20px;

}
.center_3_t{
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  color: white;
  background: rgb(216, 42, 85);
  padding: 15px 0;
  margin: 0 35px;
}
.center_3_row{
  padding-top: 10px;
  width: 100%;
  height: 120px;
  line-height: 120px;
  background-color: white;
  color: #e29065;
  font-size: 20px;
  border-bottom: 1px solid rgb(187, 182, 182);
  .col_1{
    width: 80px;
    padding-left: 25px;
  }
  .col_2{
    width: 170px;
    height: 100%;
      img{
      border-radius: 100%;
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-top: 20px;
      border:solid 1px rgb(228, 222, 222);
  }}
  .col_3{
    width: calc(100% - 480px);
  }
  .col_4{
    width: 100px;
  }
  .col_5{
    width: 120px;
  }
  
}
.row_1{
  height: 120px;
   padding: 0 10px;
   margin-top: 12px;
}
.img_attr{
  width: 20%;
  height: 100%;
  text-align: center;
 
  img{
    border-radius: 100%;
    width: 80px;
    height: 80px;
    border:solid 1px rgb(228, 222, 222);
    margin-top: -2px;
  }
  p{
    margin-top: -2px;
  }
}
.center_1{
  padding: 20px 20px 20px 20px;
}
.center_2{
  padding: 20px;
  height: 500px;
  background-color: #e25101;
}
.center_2_c{
  width: 100%;
  height: 100%;
  background-color: white;
}
.center_2_c_t{
  width: 100%;
  height: 65px;
  padding: 10px 0 0 10px;
  font-weight: 600;
}
.row{
  height: 40px;
  padding: 5px;
  line-height: 40px;
}
.top_2{
   width: 100%;
  height: 450px;
  img{
    width: 100%;
    height: 450px;
    background-position: center center;
   overflow: auto;
  }
}
.keyBoard{
  display: flex;
   bottom:0
}
.err_span{
  color: red;
  margin-left: 20px;
}
.key-container{
  left: -100px;
  z-index: 20033;
   transform: translate(0%,15%);
}


// .codetxt{
//   width: 200px;
// }
.butncode{
  background-color: white;
  color: #e5150d;
  border-radius: 100%;
  border:none

}
.el-form /deep/.el-input--prefix{
  height: 70px;
}
.el-form /deep/.el-input__inner {
border-radius: 50px !important;
border: 1px solid red !important;
height: 70px;
font-size: 24px;
width: calc(100% - 20px);

}
.el-form /deep/.el-input--prefix .el-input__inner {
  padding-left: 70px;
  padding-right: 150px;
}
    
.el-form /deep/.el-input__prefix{
  width: 70px;
  height: 70px;
  color: #e5150d;
  .el-input__icon{
    width: 100%;
    height: 100%;
  }
}
.el-form /deep/.el-input__suffix{
  width: 100px;
  margin-right: 90px;
  margin-top: 5px;
}
.el-form /deep/.el-form-item__error{
  margin-left: 30px;
  margin-top: 5px;
}
.login_container {
   display: flex;
        flex-direction: column;
        min-height: 100%;
  // position:fixed;
    //  top: 0;
    // left: 0;
  //background-image: url("../../assets/img/bg0.png");
  width: 100%;
  // background-repeat: no-repeat;
  // background-size: cover;
  // -webkit-background-size: cover;
  // -o-background-size: cover;
  // background-position: center 0;
  // transform-origin: center center;
               
}
.login_container{

 
  .login_form{
    width: 100%;
    height: 100%;
    background-color: rgba(237, 236, 236, 0);
  }
  .van-field{
    height: 5px !important;
    width: calc(100% - 40px);
    line-height: 30px;
    margin-top: 20px;
    margin-left: 10px;
    border: 1px solid #e5150d;
    border-radius: 20px;
    input{
      line-height: 30px;
    }
  }
 
  

    .iconfont {
        font-size: 70px;
    }
    .send-sms-btn{
        background-color: #ededed;
        width: 108px;
        height: 80px;
        line-height: 26px;
        .van-button__text{
            font-size: 36px;
            color: #666;
        }
        
    }
    .login-btn-wrap{
        padding: 53px 33px;
        .login-btn{
            background-color: #6db4fb;
            border: none;
        }
    }
}
.btns{
  text-align: center;
}
.btn_box_c {
  position: relative;
    left: 45%;
    top: 10%;
    transform: translate(-50%,0%);
    width: 348.09px;
    height:62.26px;
    margin: 0px 0 0 1px;
    border-radius: 10px;
    text-align: center;
   // border: 1px solid #e25101;
    background-color: #e25101;
    color: #f7e7ce;
    font-size: 36px;
    font-family: "Noto Sans SC";
    line-height: 62.26px;
    box-shadow: 0px 5px 0px #a30f02;
    bottom: 10px;
  //font-weight: 200;
  outline: 0;


}
.btn_box_c:active{
    background-color: #bd020f;
    box-shadow:  0px -5px 0px #660800;
    margin-top: 6px;
    color:#fffef5;
      outline: 0;
}
.btn_box_c:hover{
        outline: 0;
    }
.misic{
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  border: 1px solid rgb(32, 32, 32);
  top: 30%;
  z-index: 5555;
  margin-top: 20%;
  margin-right: 20px;
  left: calc(100% - 100px);
  background-color: white;
  background-image:url("../../assets/img/yinfu.png");
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center 0;
  transform-origin: center center;
  
}
.misicOpen{
  display: block;
  animation: rotate 6s linear infinite;
}

@keyframes rotate{
	0%{
		transform: rotateZ(0deg);/*从0度开始*/
	}
	100%{
		transform: rotateZ(360deg);/*360度结束*/
	}
}
.misic_line{
  border: 1px solid rgb(32, 32, 32);
  margin-top: 40px;
}
.zz{
  position:absolute;
   left:0;
   top:0;
  width: 100%;
  height: 100%;
  line-height: 100%;
  background-color: rgba(224, 223, 223, 0.6);
  z-index: 99998;
}
.model{
  z-index: 99999;
  position:absolute;
  border: 1px solid grey;
  background-color: #fff;
  top: 10%;
  width: 100%;
  .m_top{
    text-align: right;
    padding: 30px;
  }
  .m_center{
    height: 620px;
  }
  .m_qr{
    text-align: right;
    padding: 0 30px;
    height: 100px;
  }
  .m_bottom{
    text-align: center;
    padding: 30px;
    button{
    width: 100%;
    height: 1.1rem;
    background-color: rgb(217, 125, 71);
    color: white;
    border: none;
    }
  }
}
</style>