<template>
  <div id="app" class="content">
 <router-view></router-view>
  </div>
</template>
<script>
export default {
  name:'app',
  created(){
    //this.showScreen();
  },
  methods:{
    // showScreen(){
    //   window.screen.lockOrientation(['landscape-primary','landscape-secondary']);

    // }
  }
}
</script>

<style lang="less" scoped>


.content{
  // position: fixed;
  // transform-origin: center center;
  
}
</style>
