import Vue from 'vue'
import Router from 'vue-router'

const Loading = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/user/Login.vue')
const Info = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/user/Info.vue')
const Login = () =>
    import ( /* webpackChunkName: "verification" */ '../components/verification/Login.vue')
const Verify = () =>
    import ( /* webpackChunkName: "verification" */ '../components/verification/Verify.vue')
const VerifyInfo = () =>
    import ( /* webpackChunkName: "verification" */ '../components/verification/VerifyInfo.vue')
  



Vue.use(Router)

const router = new Router({
    routes: [
        { path: '/', redirect: '/applet/loading' },
        { path: '/applet/loading', component: Loading },
        { path: '/applet/info', component: Info },
        { path: '/applet/login', component: Login },
        { path: '/applet/verify', component: Verify },
        { path: '/applet/verifyInfo', component: VerifyInfo }
    ]
})

router.beforeEach( (to, from, next) => {
    // to-将要访问的路径
    // from 从哪个路径跳转而来
    // next 函数，表示放行
    if (window.location.href.indexOf("?#") < 0) {
        window.location.href = window.location.href.replace("#", "?#");
        return
      }
    if (to.path == '/applet/login') return next()
    
    if (to.path == '/applet/verify'||to.path == '/applet/verifyInfo'){ 
        if(window.sessionStorage.getItem("verificationTicket")){
            return next()
        }
        return next('/applet/login');
    }

    let fullPath = to.fullPath
    let isURL = window.location.href.indexOf('code=') === -1
    let wxcode = null;
    let superMemberId=null;
    let uOPenId=null;
    const tokenStr = window.sessionStorage.getItem("ticket");
    if (!isURL) {
        let codenum = window.location.href.indexOf('code=')
        let statenum = window.location.href.indexOf('&state=')
        if(codenum>0&&statenum>0){
            wxcode = window.location.href.slice(codenum + 5, statenum)
        }
        
        }

        let isyqURL = window.location.href.indexOf('super=') === -1
        if (!isyqURL) {
            let idNum=window.location.href.indexOf('super=');
            let idEndNum=window.location.href.indexOf('#');
            if(idNum>0&&idEndNum>0){
                superMemberId = window.location.href.slice(idNum + 6, idEndNum)
                sessionStorage.setItem("superMemberId",superMemberId)
            }
            }
        let ishbURL = window.location.href.indexOf('u_openid=') === -1
        if (!ishbURL) {
            let uNum=window.location.href.indexOf('u_openid=');
            let uEndNum=window.location.href.indexOf('&openid');
            if(uNum>0&&uEndNum>0){
                uOPenId=window.location.href.slice(uNum + 9, uEndNum)
                window.sessionStorage.setItem("uOPenId",uOPenId)
            }
            }
        
        
        
    //wxcode = 1

     let redirect_uri = "http://acrm.blu-rayvision.com",
      appid = "wx26d18707e256cfb3"
        //  let redirect_uri = "http://y40925m975.oicp.vip" + fullPath,
        //   appid = "wx4f7e9e88c4f62975"
        //redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
        // let code = to.query.code,
        //     state = to.query.state
    if (!tokenStr) {
        console.log("跳转的tokenStr：" + tokenStr);
        //未登录
        if (!wxcode) {
            console.log("跳转的地址：" + redirect_uri);
            //todo：放开
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        } else {
            window.sessionStorage.setItem('wxcode', wxcode)
            if (to.path == '/applet/loading') {
                return next();
            }
            return next('/applet/loading');
        }

    } else {
        console.log("tokenStr去登陆");
        //window.history.replaceState(null, null, window.localtion.href);
        next();
    }
    next();

})

export default router