import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import './styles/index.less'
import Vant from 'vant'
import Dialog from 'vant'
import './plugins/element'
import 'vant/lib/index.css'
import wx from 'weixin-js-sdk'
import 'lib-flexible/flexible.js'
import 'babel-polyfill'
import VueQr from 'vue-qr';
// import BaiduMap from 'vue-baidu-map'
// 加载动态设置rem基准值
import 'amfe-flexible'
import NProgress from 'nprogress'

import axios from 'axios'
axios.defaults.baseURL = 'http://acrm-api.blu-rayvision.com'

axios.interceptors.request.use(config => {
    NProgress.start()
        // config.data = { unused: 0 }
    console.log("拦截的url：" + config.url)
        // if (config.url.includes('/applet/auth/bind/code/send/')) {
        //     config.headers['Content-type'] = 'application/x-www-form-urlencoded'
        // } else {
        //     config.headers['Content-type'] = 'application/json;charset=UTF-8'
        // }
    config.headers.Authorization = window.sessionStorage.getItem('ticket')
    config.headers['ticket'] = window.sessionStorage.getItem('ticket')
    return config
})
axios.interceptors.response.use(config => {
    NProgress.done()
    return config
})

Vue.prototype.$http = axios


Vue.use(Vant)
Vue.use(Dialog)
    // Vue.use(BaiduMap), {
    //     ak: '0BLts4CNpGjrW82yjVTy2prEaI8pLPhL'
    // }

Vue.component('vue-qr',VueQr)
Vue.config.productionTip = false



new Vue({
    router,
    store,
    data: function() {
        return {}
    },
    render: h => h(App)
}).$mount('#app')