<template>
<div class="login_container">
   <div class="banner">
     <div class="tab_">
     <van-row class="font_w_2 font_1">
       门店:{{reData.storeName}}
     </van-row>
     <van-row class="goods">
       <van-col class="font_2">商品:{{reData.goodsName}}</van-col>
       <van-col class="font_2 num">数量:{{reData.goodsNum}}</van-col>
     </van-row>
     <van-row class="fx"></van-row>
     <van-row class="font_2 font_w_2">取货码</van-row>
     <van-row class="banner_center" v-if="reData.payStatus=='SUCCESS' || reData.payStatus=='USERPAYING'">
      <vue-qr :logo-src="logoSrc"
            :size="200"
            :colorDark="red"
            :margin="0"
            :dot-scale="1"
            :text="appSrc" />
     </van-row>
      </div>
      <div class="tab_ info">
        <van-row class="font_1 font_w_2 header">订单详情</van-row>
        <van-row class="font_2 title">实付金额:{{(reData.orderAmount*0.01).toFixed(2)}}</van-row>
        <van-row class="font_2 title">订单号码:{{reData.orderNo}}</van-row>
        <van-row class="font_2 title">下单时间:{{reData.serverCreateTime}}</van-row>
         <van-row class="font_2 title">订单状态:{{reData.orderStatus=='PATING'?'待支付':(reData.orderStatus=='WAIT_DELIVERY'?'待核销':(reData.orderStatus=='SUCCESS'?'订单完成':''))}}</van-row>
        <van-row class="font_2 title">核销时间:{{reData.deliveryTime}}</van-row>
      </div>
      </div>
      <div v-if="reData.payStatus!='SUCCESS' && reData.payStatus!='USERPAYING'" class="footer">
      <van-row v-if="!reData.buying" class="col_r" @click="payOrder">
        支付
      </van-row>
 </div>
   
   </div>
</template>

<script>
export default {
    data() {
    return {
      logoSrc:"",
      appSrc:"http://www.baidu.com",
      reData:{},

    }
  },
  created(){
    this.getInfo();
  },
  methods: {
    async getInfo(){

      const {data:res}=await this.$http.get("/applet/order/details?activityId=1",{},{
      });
      if(res.success){
        this.reData=res.data
        this.appSrc=res.data.orderNo
      }else{
         return this.$message.error(res.msg)
      }
    },
   async payOrder(){
  const {data:res}=await this.$http.post("/applet/order/to/pay",{orderNo:this.appSrc},{
      });
      if(res.success){
        const pay_params = res.data
            if (typeof WeixinJSBridge === "undefined"){
            if(document.addEventListener){
              document.addEventListener('WeixinJSBridgeReady', tath.onBridgeReady, false);
            }else if (document.attachEvent){
              document.attachEvent('WeixinJSBridgeReady', tath.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', tath.onBridgeReady);
            }
          }else{
            tath.onBridgeReady(pay_params);
          }
      this.$router.push('/applet/info');
      }else{
         return this.$message.error(res.msg)
      }
    },
    onBridgeReady(params) {
        var that = this
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": params.appId,  //公众号名称，由商户传入
            "timeStamp": params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            "nonceStr": params.nonceStr,  //支付签名随机串，不长于 32 位
            "package": params.package,//统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            "signType": params.signType,  //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            "paySign": params.paySign, //支付签名
          },
          function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              alert('支付成功！');
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
              alert('支付失败！');
            }
               that.$router.push('/applet/info')
          });
      },
 
  }
    
}
</script>
<style lang="less" scoped>
.login_container{
 display: flex;
  flex-direction: column;
  min-height: 100%;
}
.banner{
    flex: 1;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    bottom: 1.73333rem;
    padding: 50px;
  .tab_{
    border: 1px solid rgb(187, 185, 185);
    padding: 50px 30px;
    border-radius: 20px;
  }
}
.banner_center{
  text-align: center;
  padding-top: 30px;
}
.num{
  margin-left: 40px;
}
.goods{
  margin-top: 20px;
}
.fx{
  border: dashed 2px rgb(202, 199, 199);
  margin: 30px 0;
}
.info{
  margin-top: 30px;
  .header{
    border-bottom: 2px solid rgb(202, 199, 199);
    height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.title{
  height: 50px;
  line-height: 50px;
}
.footer{
  flex: 0;
		height: 130px;
			position: absolute;
z-index: 10;
bottom: 0;
left: 0;
right: 0;
text-align: center;
background-color: hsl(0, 11%, 95%);
border: solid 1px rgb(189, 186, 186);

    .col_r{
      width: 100%;
      background-color: rgb(202, 19, 19);
      font-size: 30px;
      line-height: 130px;
    }
		}
</style>
