<template>
    <div class="login_container">
     
      <van-row class="center">
<el-form ref="loginFormRef"  label-width="0px" class="login_form">
        <el-form-item prop="mobile" >
          <el-input prefix-icon="el-icon-mobile" type="number" placeholder="请输入手机号码" v-model="user.mobile"></el-input>
          <span class="err_span">{{err.mobile}}</span>
        </el-form-item>
        <el-form-item  prop="code">
          <el-input class="codetxt" id="code" placeholder="请输入验证码"  prefix-icon="el-icon-folder-checked" v-model="user.code" >
          <el-button  slot="suffix" v-show="btnShow" class="butncode font_3" type="info"  @click.stop="sendSmS">发送验证码</el-button>
          <el-button slot="suffix"  v-show="!btnShow"  class="butncode" type="info" >{{num}}S</el-button>
         <span class="err_span">{{err.code}}</span>
         </el-input>
        </el-form-item>
        <el-form-item class="btns">
          <div class="btn_box_c" @click="onSubmit">登录</div>
        </el-form-item>
      </el-form>
</van-row>
<div class='placeholder-view'></div>
    </div>
<!-- <div class="keyBoard">
 <keyboard v-show="showBoard" @colseEvent="_colseEvent" @confirmEvent="_confirmEvent"></keyboard>
 </div>
    </div> -->
</template>

<script>
 let Base64 = require('js-base64').Base64
export default {
    data() {
    return {
      num:60,
      btnShow:true,
      user:{
          mobile:'',
          code:''
      },
      err:{
         mobile:'',
          code:''
      },
      showBoard:false,
      flag:null,
      loginRules:{
                mobile:[
                     { required: true, message: '请输入手机号码', trigger: 'blur' }
                ],
                code:[
                     { required: true, message: '请输入验证码', trigger: 'blur' },
                ]
            },
      smsToken:'',
     
    }
  },
  created(){
   
  },
  mounted(){
     document.querySelector('.el-input__inner').focus(function(){
                  document.activeElement.blur();
       })
  },
  methods: {
    _confirmEvent(res){
      console.log('flag:'+this.flag)
      if(this.flag==1){
        if(!res){
          this.err.mobile="未填写手机号码";  
          return false; 
        }else{
          if(!(/^1[3-9]\d{9}$/.test(res))){ 
        this.err.mobile="手机号码有误，请重填";  
        return false; 
        }else{
           this.user.mobile=res
           this.err.mobile=""
           this.showBoard=false
        }
    } 
      }else if(this.flag==2){
         if(!res){
          this.err.code="未填写验证码";  
          return false; 
        }else{
           this.err.code=""
        this.user.code=res
        this.showBoard=false
      }
      }
    console.log(res)

    },
    mobile(){
      console.log('f:mobile')
      this.flag=1;
      this.showBoard=true;
    },
    code(){
      console.log('f:code')
      this.flag=2
      this.showBoard=true;
    },
    _colseEvent(){
      this.flag=0;
      this.showBoard=false;
    },
    async sendSmS(){
      const {data:res}=await this.$http.post("/applet/verification/send/login/code/"+this.user.mobile,{},{
        Headers:{'Content-type': 'application/x-www-form-urlencoded'}
      });
                if(res.success){
                   this.smsToken=res.data
                   this.$message.success("验证码已发送");
                  this.btnShow=false;

               var interval=  setInterval(() => {
                    if(this.num==0){
                      this.btnShow=true;
                      clearInterval(interval);
                    }
                    this.num=this.num-1;
                  }, 1000);
                   
                }else{
                  return this.$message.error(res.msg)

                }
    },
    async onSubmit() {
      if(!this.user.mobile){
        this.$message.error('请正确填写手机号码')
        return;
      }
      if(!this.user.code){
        this.$message.error('请输入验证码')
        return;
      }
      var pram={
         "code": this.user.code,
        "mobile": this.user.mobile,
        "token":this.smsToken
      }
      
        const {data:res}=await this.$http.post("/applet/verification/user/login",pram,{
          headers:{
            'Content-type':'application/json;charset=UTF-8',
          }
        });
                if(res.success){
                  window.sessionStorage.setItem('verificationTicket',res.data)
                    this.$router.push('/applet/verify');
                }else{
                   this.$message.error(res.msg);

                }
    },
  }
    
}
</script>

<style lang="less" scoped>
.top_1{
   margin-top: 10px;
  width: 100%;
  height: 150px;
  margin-left: 30px;
  padding-bottom: 30px;
  img{
   width: 305px;
    height: 120px;
  }
}
.top_2{
   width: 100%;
  height: 150px;
  padding: 0 60px 0 50px;
  img{
    width: 650px;
    height: auto;
  }
}
.keyBoard{
  display: flex;
   bottom:0
}
.err_span{
  color: red;
  margin-left: 20px;
}
.key-container{
  left: -100px;
  z-index: 20033;
   transform: translate(0%,15%);
}

.el-form-item{
  height: 100px;
  
}
.el-input{
  height: 20px;
  border-radius: 100% !important;
  font-size: 40px;
 
}
.el-input__inner{
  width: calc(100% - 70px) !important;
  border-radius: 10px !important;
  height: 100px !important;
}
// .codetxt{
//   width: 200px;
// }
.butncode{
  background-color: rgb(226, 222, 222);
  color: #e5150d;
  border-radius: 10px;
  border:none;
  font-size: 20px;

}
.el-form /deep/.el-input--prefix{
  height: 70px;
  line-height: 70px;
}
.el-form /deep/.el-input__inner {
border-radius: 10px !important;
border: 1px solid rgb(143, 138, 138) !important;
height: 80px;
font-size: 24px;
width: calc(100% - 20px);

}
.el-form /deep/.el-input--prefix .el-input__inner {
  padding-left: 70px;
  padding-right: 150px;
}
    
.el-form /deep/.el-input__prefix{
  width: 70px;
  height: 70px;
  line-height: 70px;
  color: #9e9998;
  .el-input__icon{
    width: 100%;
    height: 100%;
  }
}
.el-form /deep/.el-input__suffix{
  width: 100px;
  margin-right: 90px;
  margin-top: 5px;
}
.el-form /deep/.el-form-item__error{
  margin-left: 30px;
  margin-top: 5px;
}
.login_container {
  position:fixed;
     top: 0;
    left: 0;
  background-image: url("../../assets/img/bg0.png");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  transform-origin: center center;
  
               
}
.login_container{

  .center{
    position: relative;
    margin-top: 600px;
    left: 50%;
    width: 610px;
    height: 390px;
    transform: translate(-50%,-50%);
    text-align: center;
    padding: 0 30px;
  }
  .login_form{
    width: 100%;
    height: 100%;
    background-color: rgba(237, 236, 236, 0);
  }
  .van-field{
    height: 5px !important;
    width: 100%;
    line-height: 30px;
    margin-top: 20px;
    margin-left: 10px;
    input{
      line-height: 30px;
    }
  }
 
  

    .iconfont {
        font-size: 70px;
    }
    .send-sms-btn{
        background-color: #908b8b;
        width: 108px;
        height: 80px;
        line-height: 26px;
        .van-button__text{
            font-size: 36px;
            color: #666;
        }
        
    }
    .login-btn-wrap{
        padding: 53px 33px;
        .login-btn{
            background-color: #6db4fb;
            border: none;
        }
    }
}
.btns{
  text-align: center;
}
.btn_box_c {
    height:80px;
    border-radius: 10px;
    text-align: center;
   // border: 1px solid #e25101;
    background-color: #e25101;
    color: #f7e7ce;
    font-size: 36px;
    font-family: "Noto Sans SC";
    line-height: 80px;
    bottom: 10px;
  //font-weight: 200;
    outline: 0;

}
.btn_box_c:active{
    background-color: #bd020f;
    box-shadow:  0px -5px 0px #660800;
    margin-top: 6px;
    color:#fffef5;
      outline: 0;
}
.btn_box_c:hover{
        outline: 0;
    }

</style>